import React from 'react';

import { loadableReady } from 'nordic/lazy';
import { ImageProvider } from 'nordic/image';
import { hydrateRoot } from 'nordic/hydrate';

import View from '../../pages/landing/view';
import * as hotjar from '../scripts/hotjar';
import { sendViewTrack } from '../scripts/custom-view-tracking';
import * as eventTracker from '../scripts/custom-event-tracking';

/**
 * Styles
 */
import '../../pages/landing/styles.desktop.scss';

const { AndesProvider } = require('@andes/context');

const props = window.__PRELOADED_STATE__;

const initCallback = () => {
  const {
    isPreview,
    landingStatus,
    landingConfig: {
      tracking: { melidata },
    },
  } = window.__PRELOADED_STATE__;

  sendViewTrack();
  eventTracker.initEventTracking(melidata);
  hotjar.initEventTracking();

  const inIframe = window.self !== window.top;

  if (isPreview && inIframe && landingStatus?.iframeOrigin) {
    window.parent.postMessage(landingStatus, landingStatus.iframeOrigin);
  }
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
loadableReady(() => {
  hydrateRoot(
    document.getElementById('root-app'),
    <AndesProvider locale={props?.locale} device={props?.device}>
      <ImageProvider prefix={props?.imagesPrefix}>
        <View {...props} initCallback={initCallback} />
      </ImageProvider>
    </AndesProvider>,
  );
});
